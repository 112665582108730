import React from 'react'
import BreadCrumb from '../BreadCrumb/BreadCrumb'
import ImageComp from '../ImageComp/ImageComp'
import Text from '../../Components/Text/Text'

export default function HeaderSection({Src, BannerText, text, text1, text2, RouteLink, RouteLink1, RouteLink2,Color1, Color2, Color3}) {
  return (
    <div className='HeaderSection'>

<BreadCrumb text={text} text1={text1} text2={text2} RouteLink={RouteLink} RouteLink1={RouteLink1}  Color1={Color1} Color2={Color2} Color3={Color3} />

<div className="banner">
{
  Src && 
  <>
  <ImageComp Src={Src} Width="100%" />
  <div className="bannerText">
  <Text text={BannerText} FontSize="4rem" Color="white" FontWeight="bold" />
  </div>
  </>
  
}
  
  
  <div className="DotsSec">
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
    </div>
  )
}
