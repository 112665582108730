import React from 'react'
import HeaderSection from '../../Components/HeaderSection/HeaderSection'
import PageDescription from '../../Components/PageDescription/PageDescription'
import PageDescription2 from '../../Components/PageDescription2/PageDescription2'
import { Helmet } from "react-helmet";
import SolutionSlider from '../../Components/SolutionSlider/SolutionSlider'


export default function Surveillance() {
  return (
    <div className='Cybersecurity'>

<Helmet>
  <title>Surveillance</title>
</Helmet>
    <div className="Page_Body">
      <div className="Page_Body_wraper">
      <HeaderSection
        BannerText="Surveillance  "
        Src="/Images/SurveillanceBanner.webp"
        text="Solutions  <span>/</span>"
        text1="Surveillance"
        RouteLink="/#SolutionSlider"
        RouteLink1="/surveillance"
        Color1="#6C6C6C"
        Color2="black"
      />

      <PageDescription         
      text="Surveillance"
      text1="Enhance your security infrastructure with our comprehensive surveillance solutions.  Our expertise in system integration enables us to seamlessly incorporate surveillance technologies into your existing infrastructure. "

  />


        <PageDescription2
        Src="/Images/Surveillancedivider.webp"
        text="Our Expertise and Support"
        text1="Our surveillance system integration services encompass a wide range of capabilities, including video surveillance, access control, intrusion detection, and more. <br><br>

        We empower you with advance surveillance capabilities, enabling proactive monitoring, incident detection, and response. <br><br.
        
        Our solutions are scalable and adaptable, allowing for future expansion and integration with other security systems as your needs evolve.<br><br>
        
        Trust us as your partner for surveillance system integration and unlock the full potential of your security infrastructure."
        Src1="/Images/Surveillance2.webp"
        />

        {/* Solution Slider */}
      <div className="SolutionSlider">
      <SolutionSlider />
      </div>
      </div>
    </div>
       

      <div className="borderSec">
        <div className="border"></div>
      </div>

        

    </div>
  )
}
