import React from 'react'
import Text from '../Text/Text'
import ImageComp from '../ImageComp/ImageComp'

export default function VerticalSection() {
  return (
    <>
    <div className="VerticalsSecHead">
    <Text
          text="Verticals"
          FontSize="2.5rem"
          FontWeight="500"
          Margin="50px 0px 35px 0px"
        />
    </div>
      
        <div className="VerticalsSec">
        <div className="verticaleactItem">
          <ImageComp Src="/Images/Telecoms.webp" Width="100%" />
          <Text text="Telecoms" Margin="15px 0px 15px 0px" />
        </div>

        <div className="verticaleactItem">
          <ImageComp Src="/Images/Defence.webp" Width="100%" />
          <Text text="Defence" Margin="15px 0px 15px 0px" />
        </div>

        <div className="verticaleactItem">
          <ImageComp Src="/Images/Enterprise.webp" Width="100%" />
          <Text text="Enterprise" Margin="15px 0px 15px 0px" />
        </div>

        <div className="verticaleactItem">
          <ImageComp Src="/Images/Government.webp" Width="100%" />
          <Text text="Government" Margin="15px 0px 15px 0px" />
        </div>
      </div>
    </>
  )
}
