import React, { useState } from "react";
import "../../Styles/ContactUs.css";
import { Helmet } from "react-helmet";
import HeaderSection from "../../Components/HeaderSection/HeaderSection";
import PageDescription from "../../Components/PageDescription/PageDescription";
import MyButton from "../../Components/Button/Button";
import "./Career.css";
import ThreeColumnWrapper from "../../Components/ThreeColumnWrapper/ThreeColumnWrapper";
import SolutionSlider from "../../Components/SolutionSlider/SolutionSlider";

export default function Career() {
  return (
    <>
      <div className="CareerPage">
        <Helmet>
          <title>Careers</title>
        </Helmet>
        <HeaderSection
          BannerText=""
          Src="/Images/careerbanner.png"
          text="Company <span>/</span>"
          text1="Careers"
          RouteLink="/"
          RouteLink1="/career"
          Color1="#6C6C6C"
          Color2="black"
        />
        <PageDescription
          text="Life at <br>DynaSys Networks"
          text1="DynaSys Networks distinguishes itself through a steadfast commitment to innovation, collaboration, and cultivating a supportive culture. Here, every team member is empowered to make unique contributions, propelling technological advancement, and fostering significant impact."
        />
        <div className="CurrentOpeningsButton">
          <MyButton
            RouteLink={"/available_positions"}
            text={"Current Openings"}
            Margin={"0% 0% 2% 35%"}
            BGcolor={"#3D3D3D"}
            Color={"white"}
            Padding={"1% 2%"}
            Radius={"8px"}
            FontWeight={"400"}
          />
        </div>
        <ThreeColumnWrapper
          ImgSrc1={"/Images/careerimage1.png"}
          Col1Text={
            "<span>Our Culture</span> <br> <br> DynaSys Networks nurtures a culture of collaboration, innovation, and passion for technology. <br><br>We prioritize transparent communication, champion diversity, and uphold steadfast accountability. <br><br> Within our dynamic environment, we foster continuous improvement, empowering adaptation and flourishing in a rapidly changing industry landscape."
          }
          Col2Text={
            "<span>Innovation</span> <br> <br> Innovation is core to our ethos at DynaSys Networks. <br> <br> We consistently push the limits of technology, exploring new ideas to craft advanced solutions for present and future challenges.  <br> <br></br> Our team experiments, learn from setbacks, and celebrate successes, in relentless pursuit of sustained innovation and excellence."
          }
        />
        <ThreeColumnWrapper
          ImgSrc2={"/Images/careerimage2.png"}
          Col1Text={
            "<span>Core Values</span> <br> <br> We are guided by a commitment to Ideation, Innovation, and precise Implementation. <br><br> Our dedication to the highest ethical standards ensures exceptional quality across all initiatives. Through collaborative synergy, we cultivate creativity and strive to make a meaningful impact on communities globally."
          }
          Col2Text={
            "<span>Our Environment</span> <br> <br> Fostering a positive work environment is paramount at DynaSys Networks. <br><br>Our offices inspire creativity and collaboration, offering modern amenities and flexible arrangements for work-life balance. <br><br>We prioritize employee well-being, invest in growth opportunities, and remain committed to environmental sustainability in all operations."
          }
        />
        {/* Solution Slider */}
        <div className="SolutionSlider">
          <SolutionSlider />
        </div>
      </div>
      <div className="borderSec">
        <div className="border"></div>
      </div>
    </>
  );
}
