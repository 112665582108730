import React from 'react'
import { Link } from 'react-router-dom';
import Text from '../Text/Text';
import ImageComp from '../ImageComp/ImageComp';

import "./SolutionSliderEachItem.css"

export default function SolutionDropdown({RouterLink, Src, text}) {
  
  return (
    <div className='SolutionSliderEachItem'>
        <div className="SolutionSliderEachItem">
              <Link to={RouterLink}>
                <ImageComp Src={Src} Width="100%" />
                <Text text={text} Margin="15px 0px 15px 0px" FontSize="1.4rem" FontWeight="600" />
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
              </Link>
            </div>
    </div>
  )
}
