import React from 'react'
import { Link } from 'react-router-dom'
import ImageComp from '../../../../Components/ImageComp/ImageComp'
import Text from '../../../../Components/Text/Text'
import "./LeaderShipLink.css"

export default function LeaderShipLink({RouteLink, Src, LeaderName, LeaderDesignation, Width}) {
  return (
    <div className='LeaderShipLink'>
      <Link to={RouteLink}>
          <div className="leadership">
            <div className="leaderImg">
            <ImageComp Src={Src} Radius="120px" Width={Width} />
            </div>
            <div className="leaderDetails">
                    <Text text={LeaderName} FontWeight="600" FontSize="2.4rem"/>
                    <Text text={LeaderDesignation} FontWeight="600" FontSize="1.6rem"/>
                </div>
            </div>
          </Link>
    </div>
  )
}
