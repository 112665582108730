import React from "react";
import "./SingleNews.css"
import Text from "../Text/Text";
import ImageComp from "../ImageComp/ImageComp";
import { Link } from "react-router-dom";

export default function SingleNews({newsdate, newshead, newsdetails, newsimage, newslink, Width, ImgWidth}) {
  return (
    <div className="NewsWraper">
      <Link to={newslink}>
        
        <div className="News_Container">
        
        <div className="NewsText" >
        <div className="News_Date">
            <Text text={newsdate} Margin="0px 0px 13px 0px" Color="#808080" FontSize="1.2rem" FontWeight="700" />
        </div>
        <div className="News_head">
        <Text
            text={newshead}
            FontSize="1.8rem"
            FontWeight="500"
            Width={Width}
          />
        </div>
          
          <Text
            text={newsdetails}
            FontSize="1.5rem"
            FontWeight="400"
            Margin="13px 0px 0px 0px"
          />
        </div>
        <div className="News_Image">
              <ImageComp Src={newsimage} Width={ImgWidth} />
            </div>
        </div>
        
      </Link>
    </div>
  );
}
