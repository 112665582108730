import React from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import Text from "../../../Components/Text/Text";
import Footer from "../../../Components/Footer/Footer";
import { Helmet } from "react-helmet";

export default function ImranFarooq() {
  return (
    <div className="LeaderShip">
      <Helmet>
        <title>Chief Technology Officer</title>
      </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Our Leadership "
            Src="/Images/leadershopbanner.webp"
            text="About Us <span>/</span>"
            text1="Our Leadership "
            RouteLink="/#aboutus"
            RouteLink1="/leadership"
            Color2="black"
            Color1="#6C6C6C"
          />

          <div className="leadershipdetails">
            <Text text="Imran Farooq " FontSize="4rem" FontWeight="700" />
            <Text
              text="Chief Technology Officer"
              FontSize="1.8rem"
              FontWeight="700"
            />
          </div>
          <div className="leadershipdetails2">
            <div className="leadershipImageMob">
              <ImageComp
                Src="/Images/imranleadershipwraperimage.webp"
                Radius="120px"
              />
            </div>
            <div className="leaderhsipconent">
              <Text
                text="Imran is a celebrated telecommunications professional with over 19 years of hands-on experience in the telecommunications industry. After graduating from Pakistan’s Sir Syed University of Engineering and Technology with a degree in Computer Engineering. Imran Farooq started his career as a Network Engineer in the telecommunications industry in 2004."
                Color="black"
              />
              <Text
                text="His expertise spans various aspects of IT & telecommunications technologies, including satellite communications, network security, network architecture, project management, designing and optimizing network solutions, integrating emerging technologies, as well as network operations, maintenance, and troubleshooting."
                Margin="35px 0px"
                Color="black"
              />
              <Text
                text="Imran has a proven track record of driving technological advancements and successfully delivering large scale projects for the telecommunications, defence, government, and enterprise markets."
                Color="black"
                Margin="35px 0px"
              />
              <Text
                text="Prior to Joining DynaSys, Imran was at Supernet, the Pakistan-based systems integrator and service provider where he was the Senior Manager for Access Networks."
                Color="black"
                Margin="35px 0px"
              />
            </div>
            <div className="leadershipImage">
              <ImageComp
                Src="/Images/imranleadershipwraperimage.webp"
                Radius="120px"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>
        
    </div>
  );
}
