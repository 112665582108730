import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import Navbar from "../../../Components/Navbar/Navbar";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import MyButton from "../../../Components/Button/Button";
import Footer from "../../../Components/Footer/Footer";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";

export default function ImranFarooqPressRelease() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>Imran Farooq Press Release</title>
      </Helmet>
      
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Press Releases"
            // RouteLink="/press_releases/hamid_nawaz_press_release"
            RouteLink1="/press_releases"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
            <div className="PressReleaseBodyTopText">
              <Text
                text="Imran Farooq Joins DynaSys Networks as Chief Technology Officer"
                FontWeight="900"
                FontSize={"3.5rem"}
                font
                Margin="50px 0px 50px 0px"
              />
              <Text
                text="Fri 25 Aug, 2023"
                FontWeight="500"
                Color="#808080"
                FontSize={"1.8rem"}
                font
                Margin="50px 0px 20px 0px"
              />
              <Text
                text="With over 19 years of in-depth IT, Telecoms and Satellite Communications experience, Imran Farooq provides a major boost to the technology team at DynaSys Networks."
                FontSize={"3.2rem"}
                Margin="0px 0px 50px 0px"
                Padding="0px 150px 0px 0px"
              />
            </div>

            <div className="PressRelease_Img_Sec">
              <div className="Right_Sec_Mob">
                <ImageComp Src={"/Images/imranFarooqPressReleaseimage.webp"} Width="100%" />
              </div>
              <div className="Left_Sec">
                <Text
                  text="<span>25<sup>th</sup> August 2023 – Rawalpindi, Pakistan</span> – DynaSys Networks announced today that Mr. Imran Farooq has joined the organization as Chief Technology Officer."
                  FontSize="2.1rem" 
                />
                <Text
                  text="Imran is a celebrated telecommunications professional with over 19 years of hands-on experience in the telecommunications industry. After graduating from Pakistan’s Sir Syed University of Engineering and Technology with a degree in Computer Engineering. Imran Farooq started his career as a Network Engineer in the telecommunications industry in 2004."
                  FontSize="2.1rem"
                  Margin="30px 0px 30px 0px"
                />
                <Text
                  text="His expertise spans various aspects of IT & telecommunications technologies, including satellite communications, network security, network architecture, project management, designing and optimizing network solutions, integrating emerging technologies, as well as network operations, maintenance, and troubleshooting."
                  FontSize="2.1rem"
                />
              </div>
              <div className="Right_Sec">
                <ImageComp Src={"/Images/imranFarooqPressReleaseimage.webp"} Width="96%" />
              </div>
            </div>
            <Text text="Imran has a proven track record of driving technological advancements and successfully delivering large scale projects for the telecommunications, defence, government, and enterprise markets." />

            <Text Margin="30px 0px 30px 0px" text="Prior to Joining DynaSys, Imran was at Supernet, the Pakistan-based systems integrator and service provider where he was the Senior Manager for Access Networks." />

            <Text Margin="0px 0px 30px 0px" text="“It gives me great joy to welcome Imran to DynaSys. I have worked with Imran for the past thirteen years and we’ve done some amazing work together. His excellent technical skillset and hands-on approach underscores the leadership that he’ll bring to the organization especially at a time when we’re exponentially expanding”, stated Ali Akhtar, Founder & CEO, DynaSys Networks." />

            <Text text="“I’m excited to dive right into the technology behind the products and services that DynaSys is developing and offering. The push for digitization through IoT is particularly refreshing. I look forward to successfully materializing the initiatives DynaSys has undertaken and further strengthening the technical pillars of the company”, says Imran Farooq, CTO, DynaSys Networks." />


            <div className="Press_Release_bottom">
              <div className="SiteLinkSec">
                <Text
                  text="For any inquiries, please reach out at"
                  Margin="0px 2px 0px 0px"
                  FontSize="1.8rem"
                />
                <MyButton
                  RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com"
                  FontSize="1.8rem"
                  text="info@dynasysnetworks.com"
                  FontWeight="400"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
        
    </div>
  );
}
