import React from "react";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import "../Styles/LeaderShip.css";
import Footer from "../../../Components/Footer/Footer";
import Navbar from "../../../Components/Navbar/Navbar";

import { Helmet } from 'react-helmet';


export default function AliCEO() {
  return (
    <div className="LeaderShip">
      <Helmet>
        <title>Founder & CEO</title>
        </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Our Leadership "
            Src="/Images/leadershopbanner.webp"
            text="About Us <span>/</span>"
            text1="Our Leadership "
            RouteLink="/#aboutus"
            RouteLink1="/leadership"
            Color2="black"
            Color1="#6C6C6C"
          />

          <div className="leadershipdetails">
            <Text text="Ali Akhtar" FontSize="4rem" FontWeight="700" />
            <Text text="Founder & CEO" FontSize="1.8rem" FontWeight="700" />
          </div>
          <div className="leadershipdetails2">
            <div className="leadershipImageMob">
              <ImageComp Src="/Images/leadershipImage.png" />
            </div>
            <div className="leaderhsipconent">
              <Text
                text="Graduating as telecommunications engineer,  Ali joined the satellite communications industry in 2010, beginning his career as a systems engineer. He has held leadership roles in product management, commercial management and business unit management. "
                Color="black"
              />
              <Text
                text="As a key executive of the satcomms industry, Ali has had a lead role in coming up with practical solutions to connect the unconnected. He also has a rich and diverse profile in cybersecurity, transmission and networking solutions.  "
                Margin="35px 0px"
                Color="black"
              />
              <Text
                text="Ali’s accomplishments have earned international recognition like the Community Effort Award and multiple Innovation Awards from Yahsat and the Young Talent Award from Asia Pacific Satellite Communications Council. The Space and Satellite Professionals International has included Ali in the  “20 under 35” list of professionals having a profound impact on the industry. "
                Color="black"
              />
              <Text
                text="Ali is a fellow of the USA based institute of Space Commerce.  "
                Color="black"
                Margin="35px 0px"
              />
            </div>
            <div className="leadershipImage">
              <ImageComp Src="/Images/leadershipImage.png" />
            </div>
          </div>
        </div>
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>
        
    </div>
  );
}
