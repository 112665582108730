import React from "react";
import HeaderSection from "../../Components/HeaderSection/HeaderSection";
import PageDescription from "../../Components/PageDescription/PageDescription";
import PageDescription2 from "../../Components/PageDescription2/PageDescription2";
import SolutionSlider from "../../Components/SolutionSlider/SolutionSlider";
import { Helmet } from "react-helmet";

export default function Professionalservices() {
  return (
    <div className="Cybersecurity">
      <Helmet>
        <title>Professional Services</title>
      </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Professional<br>Services "
            Src="/Images/propessionalServiceBAnner.webp"
            text="Solutions <span>/</span>"
            text1="Professional Services "
            RouteLink="/#SolutionSlider"
            RouteLink1="/professionalservices"
            Color1="#6C6C6C"
            Color2="black"
          />

          <PageDescription
            text="Professional <br> Services "
            text1="At DynaSys Networks, our solutions are fueled by a team of dedicated engineering experts and a network of support specialists. From conceptualisation to implementation, we work closely with you to ensure that our solutions align with your business objectives and drive tangible results."
          />

          <PageDescription2
            Src="/Images/professionalServiceDivider.webp"
            text="Professional Services and Support"
            text1="We take pride in our ability to deliver top-notch solutions backed by highly dedicated professionals. <br><br>

        When you partner with DynaSys Networks, you can trust that you are working with a team that is passionate about delivering excellence and exceeding your expectations.<br><br>
        
        Contact us today to learn more about how our team can help you overcome challenges and achieve success in your endeavors."
            Src1="/Images/professionalservice2.webp"
          />

          {/* Solution Slider */}
          <div className="SolutionSlider">
            <SolutionSlider />
          </div>
        </div>
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>
    </div>
  );
}
