import React from 'react'
import MyButton from '../Button/Button'
import "./BreadCrumb.css"
import { HashLink } from 'react-router-hash-link'
import Text from '../Text/Text'
export default function BreadCrumb({RouteLink, text, RouteLink1, text1, RouteLink2, text2, Color1, Color2, Color3}) {
  return (
    <div className='BreadCrumb'>
    {
      RouteLink ?
      <HashLink smooth to={RouteLink}>
      <MyButton text={text} FontSize="2rem"  FontWeight="700" Color={Color1} />
      </HashLink>:
      <Text text={text} FontSize="2rem"  FontWeight="700" Color={Color1} />
      

    }
      
        <MyButton RouteLink={RouteLink1} text={text1} FontSize="2rem"  FontWeight="700" Color={Color2}  Margin="0px 0px 0px 6px"/>

    </div>
  )
}
