import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import "../Styles/MousSigningCeremony.css";
import EachPressReleaseComponent from "../Components/EachPressReleaseComponent/EachPressReleaseComponent";

export default function DynaSysTriumphsTwiceatHBLPasha() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>Dynasys Triumphs Twice at HBL Pasha</title>
      </Helmet>

      <EachPressReleaseComponent
        PressHeading={"DynaSys Triumphs Twice at HBL P@SHA ICT Awards 2024"}
        PressDate={"Mon 23 Sep, 2024"}
        PressDetails={
          "<span>Islamabad, Pakistan – 23 September 2024    </span>  – DynaSys Networks, a leading innovator in Internet of Things technologies and services, is thrilled to announce that it won two awards at Pakistan’s prestigious HBL P@SHA ICT Awards 2024. DynaSys has been recognized for its exceptional contributions in the following categories:<br><br><span>Internet of Things: </span> DynaSys&#39; cutting-edge IoT ecosystem has been lauded for its innovation, impact, and potential to revolutionize industries across Pakistan.<br><br><span>Startup: </span>DynaSys' journey as a dynamic and promising startup has been acknowledged, highlighting its rapid growth, innovative spirit, and potential to drive positive change in the technology landscape.<br><br>This dual recognition at Pakistan’s “Oscars of ICT” is a testament to DynaSys&#39; commitment to excellence, technological advancements, and its dedication to shaping a brighter digital future for Pakistan.<br><br>P@SHA is Pakistan’s association of IT and IT enabled companies. With 1600+ members, P@SHA is supporting its member organizations in building a conducive environment to operate while lobbying with the Government, building a global brand and visibility, and initiating programs to build skills and capacity of the IT industry of Pakistan.<br><br>During the award ceremony Ali Akhtar, Founder and CEO of DynaSys Networks stated “We are deeply honored to receive these prestigious awards. This recognition is a validation of our team&#39;s hard work, dedication, and unwavering pursuit of innovation. We are committed to continuing our mission of empowering businesses and individuals with cutting-edge IoT solutions.”"
        }
        PressImage={"/Images/DynaSysTriumphsTwiceatHBLPressRelease.png"}
        PressImageCaption={"Shahid Manzoor, Manager Sales and BD (Left), Ali Akhtar, Founder and CEO of DynaSys Networks (Center), Uzair Akhtar, Product Specialist - IoT (Right)."}
      />
    </div>
  );
}
