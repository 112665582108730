import React from 'react'
import HeaderSection from '../../Components/HeaderSection/HeaderSection'
import PageDescription from '../../Components/PageDescription/PageDescription'
import PageDescription2 from '../../Components/PageDescription2/PageDescription2'
import { Helmet } from "react-helmet";
import SolutionSlider from '../../Components/SolutionSlider/SolutionSlider'


export default function PowerSolutions() {
  return (
    <div className='Cybersecurity'>

<Helmet>
  <title>Power Solutions</title>
</Helmet>
    <div className="Page_Body">
      <div className="Page_Body_wraper">
      <HeaderSection
        BannerText="Power Solutions  "
        Src="/Images/PowerSolutionsBAnner.webp"
        text="Solutions <span>/</span>"
        text1="Power Solutions "
        RouteLink="/#SolutionSlider"
        RouteLink1="/powersolutions"
        Color1="#6C6C6C"
        Color2="black"
      />

      <PageDescription         
      text="Power<br> Solutions "
      text1="Experience reliable and efficient power solutions with our comprehensive system integration services. <br>
      Our expertise in power system integration enables us to design and implement customized solutions tailored to your specific requirements. We handle the entire process, from system design and implementation to ongoing maintenance and support. "

  />


        <PageDescription2
        Src="/Images/PowerSolutionsDivider.webp"
        text="Professional Services and Support"
        text1="Our power solutions encompass a wide range of capabilities, including backup power systems, renewable energy and management systems. <br><br>

        Whether you require backup power for critical operations or seek to integrate renewable energy sources, our solutions are scalable and adaptable to meet your evolving needs. <br><br>
        
        Our power solutions help ensures uninterrupted operations and minimizing downtime."
        Src1="/Images/PowerSolutions2.webp"
        />

        {/* Solution Slider */}
      <div className="SolutionSlider">
      <SolutionSlider />
      </div>
      </div>
    </div>
        

      <div className="borderSec">
        <div className="border"></div>
      </div>

        

    </div>
  )
}
