import React, { useEffect, useState } from "react";
import HeaderSection from "../../Components/HeaderSection/HeaderSection";
import ImageComp from "../../Components/ImageComp/ImageComp";
import "../../Styles/IotNew.css";
import "../../Styles/ContactUs.css";
import Text from "../../Components/Text/Text";
import { Helmet } from "react-helmet";
import MyButton from "../../Components/Button/Button";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import SolutionSlider from "../../Components/SolutionSlider/SolutionSlider";

// Define your services data
const services = [
  {
    id: 1,
    image: "/Images/TerraMonitor.png",
    heading: "TerraMonitor",
    details:
      "Empower your facility with TerraMonitor, an advanced IoT solution revolutionizing facility and asset monitoring. Includes",
    listItems: [
      "Water Monitoring",
      "Air Quality Monitoring",
      "Asset Monitoring",
      "Agriculture Monitoring",
    ],
    linkFile: "/catalogues/TerraMonitor.pdf",
  },
  {
    id: 2,
    image: "/Images/umeter.png",
    heading: "UMeter",
    details:
      "Keep track of your utility consumption, giving you the power to conserve resources, reduce costs, and ensure sustainability. Includes ",
    listItems: ["Gas Metering", "Water Metering", "Power Metering"],
    linkFile: "/catalogues/UMeter.pdf",
  },
  {
    id: 3,
    image: "/Images/dynalock.png",
    heading: "DynaLock",
    details:
      "DynaLock is part of the movement towards smarter, more intuitive access control. From managing parking spaces to safeguarding assets with intelligent padlocks. Includes",
    listItems: ["Smart Locks", "Smart Parking Lock"],
    linkFile: "/catalogues/DynaLock.pdf",
  },
  {
    id: 4,
    image: "/Images/dynatrace.png",
    heading: "DynaTrace",
    details:
      "Tracking your assets are at any given moment is a crucial part of effective management. DynaTrace brings this ability to your fingertips, offering comprehensive indoor and outdoor tracking solutions. Includes",
    listItems: ["Asset Tracking", "Personnel Tracking", "Mobile Storage"],
    linkFile: "/catalogues/DynaTrace.pdf",
  },
  {
    id: 5,
    image: "/Images/citysense.png",
    heading: "CitySense",
    details:
      "CitySense is a video analytics platform tailored for comprehensive urban management. By merging Smart Parking, Smart Space Management, and Smart Facility Analytics, our system offers a synergy of solutions. Includes",
    listItems: [
      "Smart Parking",
      "Smart Space Management",
      "Smart Facility Analytics",
    ],
    linkFile: "/catalogues/CitySense.pdf",
  },
  {
    id: 6,
    image: "/Images/dynafleet.png",
    heading: "DynaFleet",
    details:
      "Modern logistics and operational demands require sophisticated fleet management solutions. With DynaFleet puts you in driver seat of every vehicle of your fleet, you’re not just tracking vehicles, you’re optimizing entire fleets. Includes",
    listItems: ["Fleet Tracker", "Fuel Tracker", "Vehicle Speed"],
    linkFile: "/catalogues/DynaFleet.pdf",
  },
  {
    id: 7,
    image: "/Images/ecoclean.png",
    heading: "EcoClean",
    details:
      "In an era where urban cleanliness and public health are paramount, EcoClean emerges as a beacon of innovation. It focuses on cleanliness applications, seamlessly integrating IoT to provide real-time solutions. Includes",
    listItems: ["Smart Rodent Monitoring", "Smart Waste Management"],
    linkFile: "/catalogues/EcoClean.pdf",
  },
  {
    id: 8,
    image: "/Images/intellipark.png",
    heading: "IntelliPark",
    details:
      "The need for efficient vehicle entry and exit management has never been greater. IntelliPark harnesses the power of Advanced Number Plate Recognition (ANPR) to transform conventional access points into state-of-the-art controlled passages. Includes",
    listItems: ["Smart ANPR Access Management", "Smart ANPR (Commercial)"],
    linkFile: "/catalogues/IntelliPark.pdf",
  },
  {
    id: 9,
    image: "/Images/smartSecurity.png",
    heading: "SmartSentry",
    details:
      "SmartSentry is a system made to seamlessly centralize incident and alarm management for all things IoT, while also integrating intelligent security and safety systems. Includes",
    listItems: ["Intelligent Security", "Intelligent Safety"],
    linkFile: "/catalogues/SmartSentry.pdf",
  },
  // Add more services as needed
];

const ServiceItem = ({ service }) => (
  <div className="IotServicesEach">
    <img src={service.image} alt={service.heading} />
    <Text
      FontSize="2rem"
      FontWeight="500"
      Margin={"1.8rem 0px 2rem 0px"}
      text={service.heading}
    />
    <Text FontSize="1.8rem" Color={"#6C6C6C"} text={service.details} />
    {/* <Text
      FontSize="2rem"
      FontWeight="500"
      Color={"#6C6C6C"}
      Margin={"1.8rem 0px 2rem 0px"}
      text={"Solution includes"}
    /> */}
    <ul>
      {service.listItems.map((item, index) => (
        <li key={index}>
          <Text FontSize="1.8rem" Color={"#6C6C6C"} text={item} />
        </li>
      ))}
    </ul>
    <MyButton
      text={"more details"}
      RouteLink={service.linkFile}
      target={"_blank"}
      FontSize={"1.6rem"}
      FontWeight={"500"}
      TextTransform={"capitalize"}
      Border={"1px dashed #212121"}
      Padding={"12px"}
      BGcolor={"#EFEFEF"}
      Radius={"8px"}
    />
  </div>
);



function ContactForm() {
      // spinner loader
let [loading, setLoading] = useState(false);
let [color, setColor] = useState("white");
  const [formData, setFormData] = useState({});

  function validEmail(email) {
    var re =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }

  function validateHuman(honeypot) {
    if (honeypot) {
      console.log("Robot Detected!");
      return true;
    } else {
      console.log("Welcome Human!");
    }
  }

  function getFormData(form) {
    var elements = form.elements;

    var fields = Object.keys(elements)
      .filter(function (k) {
        return elements[k].name !== "honeypot";
      })
      .map(function (k) {
        if (elements[k].name !== undefined) {
          return elements[k].name;
        } else if (elements[k].length > 0) {
          return elements[k].item(0).name;
        }
      })
      .filter(function (item, pos, self) {
        return self.indexOf(item) == pos && item;
      });

    var formData = {};
    fields.forEach(function (name) {
      var element = elements[name];
      formData[name] = element.value;
      if (element.length) {
        var data = [];
        for (var i = 0; i < element.length; i++) {
          var item = element.item(i);
          if (item.checked || item.selected) {
            data.push(item.value);
          }
        }
        formData[name] = data.join(", ");
      }
    });

    // add form-specific values into the data
    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || "Sheet1"; // default sheet name
    formData.formGoogleSendEmail = form.dataset.email || ""; // no email by default

    console.log(formData);
    return formData;
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const data = getFormData(form);

    if (data.email && !validEmail(data.email)) {
      var invalidEmail = form.querySelector(".email-invalid");
      if (invalidEmail) {
        invalidEmail.style.display = "block";
        return false;
      }
    } else {
      setLoading(true)
      disableAllButtons(form);
      var url = form.action;
      var xhr = new XMLHttpRequest();
      xhr.open("POST", url);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.onreadystatechange = function () {
        console.log(xhr.status, xhr.statusText);
        console.log(xhr.responseText);
        var formElements = form.querySelector(".form-elements");
        if (formElements) {
          formElements.style.display = "none"; // hide form
        }
        var thankYouMessage = form.querySelector(".thankyou_message");
        if (thankYouMessage) {
          setLoading(false)
          thankYouMessage.style.display = "flex";
        }
        return;
      };
      var encoded = Object.keys(data)
        .map(function (k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
        })
        .join("&");
      xhr.send(encoded);
    }
  }

  function disableAllButtons(form) {
    var buttons = form.querySelectorAll("button");
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }
  }

  return (
    <form
      className="gform pure-form pure-form-stacked"
      onSubmit={handleFormSubmit}
      // data-email="muslimhussain89@gmail.com"
      data-email="info@dynasysnetworks.com"
      action="https://script.google.com/macros/s/AKfycbyKpiYx1BmKFXQZ-NLZ7mLOmUC7aGJtPEde3sFtmJObMRMSDJCk4egGRVFap6i1XxIQ/exec"
    >
      <input type="text" name="Name" placeholder="Name" required />
      <input type="email" name="Email" placeholder="Email" required />
      <input type="text" name="Company Name" placeholder="Company Name" />
      <textarea
        name="Message"
        cols="10"
        rows="1"
        placeholder="What would you like to discuss"
        required
      ></textarea>
      <div className="Send_Spinner">
      <button className="submit press-effect " >send</button>
      <div className="sweet-loading">

        <ClipLoader
          color={color}
          loading={loading}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      </div>
      
      <div className="thankyou_message">
        <h1>Thanks for contacting us!</h1>
        <div className="close-btn">
          <Link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </Link>
        </div>
      </div>
    </form>
  );
}

export default function IotNew() {



  // USE STATE FOR THE MODAL
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (showModal) {
        event.preventDefault();
      }
    };

    if (showModal) {
      // Add event listener to the window
      window.addEventListener("scroll", handleScroll, { passive: false });
    } else {
      // Remove event listener if showModal is false
      window.removeEventListener("scroll", handleScroll);
    }

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showModal]); // Re-run effect whenever showModal changes



  return (
    <div
      className="Iot"
      style={{ backgroundColor: `${showModal ? "#4F4F4F" : "white"}` }}
    >
      {/* <div className="Iot" style={{backgroundColor:`${showModal ? "#4F4F4F" : "white" }` }}> */}

      <Helmet>
        <title>Internet of Things</title>
      </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Internet of Things "
            Src="/Images/iotBannerNew.png"
            text="Solutions <span>/</span>"
            text1="Internet of Things"
            RouteLink="/#SolutionSlider"
            RouteLink1="/iot"
            Color1="#6C6C6C"
            Color2="black"
          />

          <div className="IotAlefSec">
            <div className="AlefImg">
              <ImageComp Src={"./Images/iotBanner.png"} />
            </div>
            <div className="AlefService">
              <Text
                FontSize="2.5rem"
                FontWeight="600"
                Color={"#6C6C6C"}
                text={
                  "ALEF serves as a comprehensive ecosystem that revolutionizes the way organizations approach IoT. Designed to simplify your journey from data collection to insightful action, ALEF offers an unparalleled user experience coupled with robust functionalities."
                }
              />
              <div className="CatalogueButton">
                <a href="/catalogues/IoT Catalogue.pdf" target="black">
                  Download IoT Catalogue
                </a>
              </div>
            </div>
          </div>

          {/* Unleashing the Power of Device Management */}
          <div className="PowerDeviceManagemnet">
            <Text
              text="Unleashing the <br> Power of Device <br>  Management"
              FontSize="2.5rem"
              FontWeight="500"
              Margin="0px 0px 50px 0px"
              Color={"#2D2D2D"}
            />
            <div className="PowerDeviceManagemnetCpanel">
              <div className="CpanelCol1">
                <ImageComp
                  Src={"./Images/PowerDeviceManagemnet.png"}
                  Width={"100%"}
                />
              </div>
              <div className="CpanelCol2">
                <div className="CpanleDetail1">
                  <Text
                    text="Full Control and Visibilitys"
                    FontSize="2rem"
                    FontWeight="600"
                    Margin="15px 0px 25px 0px"
                  />
                  <Text
                    FontSize="1.6rem"
                    FontWeight="500"
                    Color="#6C6C6C"
                    text="Enjoy comprehensive visibility and control over all devices through a centralized user-friendly dashboard."
                  />
                </div>
                <div className="CpanleDetail1">
                  <Text
                    text="Easy Device Configuration"
                    FontSize="2rem"
                    FontWeight="600"
                    Margin="15px 0px 25px 0px"
                  />
                  <Text
                    FontSize="1.6rem"
                    FontWeight="500"
                    Color="#6C6C6C"
                    text="Manage a variety devices seamlessly to meet your specific needs with ALEF."
                  />
                </div>
                <div className="CpanleDetail1">
                  <Text
                    text="Real-time Alerts"
                    FontSize="2rem"
                    FontWeight="600"
                    Margin="15px 0px 25px 0px"
                  />
                  <Text
                    FontSize="1.6rem"
                    FontWeight="500"
                    Color="#6C6C6C"
                    text="Stay updated with real-time device status alerts for prompt decision making and smooth operations."
                  />
                </div>
                <div className="CpanleDetail1">
                  <Text
                    text="Scale with Ease"
                    FontSize="2rem"
                    FontWeight="600"
                    Margin="15px 0px 25px 0px"
                  />
                  <Text
                    FontSize="1.6rem"
                    FontWeight="500"
                    Color="#6C6C6C"
                    text="The ALEF IoT Platform scales effortlessly with your business needs, supporting a large number of devices without compromising efficiency."
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Choose Your Preferred Deployment Method */}
          <div className="PreferredDeploymentMethod">
            <Text
              text="Choose Your Preferred <br> Deployment Method"
              FontSize="2.5rem"
              FontWeight="500"
              Margin="0px 0px 50px 0px"
              Color={"#2D2D2D"}
            />
            <div className="DeploymentMethod">
              <div className="Method1">
                <ImageComp Src={"./Images/cloud 1.png"} />
                <Text
                  text="Cloud"
                  FontSize="2rem"
                  FontWeight="600"
                  Margin="25px 0px 25px 0px"
                />
                <Text
                  FontSize="1.6rem"
                  FontWeight="500"
                  Color="#6C6C6C"
                  text="Effortlessly scale up, access from anywhere, and enjoy robust security without the burden of hardware expenses."
                />
              </div>
              <div className="Method1">
                <ImageComp Src={"./Images/database 2.png"} />
                <Text
                  text="On-Premise"
                  FontSize="2rem"
                  FontWeight="600"
                  Margin="25px 0px 25px 0px"
                />
                <Text
                  FontSize="1.6rem"
                  FontWeight="500"
                  Color="#6C6C6C"
                  text="Ensure full data control, minimize latency, and tailor security to meet compliance requirements."
                />
              </div>
              <div className="Method1">
                <ImageComp Src={"./Images/hybrid.png"} />
                <Text
                  text="Hybrid"
                  FontSize="2rem"
                  FontWeight="600"
                  Margin="25px 0px 25px 0px"
                />
                <Text
                  FontSize="1.6rem"
                  FontWeight="500"
                  Color="#6C6C6C"
                  text="Experience the scalability of the cloud while maintaining control of your data on-premises."
                />
              </div>
            </div>
          </div>

          {/* SHOW MODAL WHEN CLICK ON REQUEST DEMO */}
          {showModal && (
            <div className="RequestDemoModal">
              <div onClose={handleCloseModal}>
                <div className="contactForm">
                  <ContactForm />
                  <button onClick={handleCloseModal} className="CloseModal">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div class="pageDivider">
            <ImageComp Src={"/Images/IotDivider.webp"} />
            <div className="RequestDemoBtn">
              <MyButton
                text={"Request Demo"}
                FontWeight={"500"}
                handleClick={handleShowModal}
              />
            </div>
          </div>

          {/* IOT  Product */}
          <div className="IoTProductsHead">
            <Text
              text="Our IoT <br> Products"
              FontSize="2.5rem"
              FontWeight="500"
              Margin="50px 0px 50px 0px"
              Color={"#2D2D2D"}
            />
          </div>
          <div className="IotServices">
            {services.map((service) => (
              <ServiceItem key={service.id} service={service} />
            ))}
          </div>

          {/* Solution Slider */}
          <div className="SolutionSlider">
            <SolutionSlider />
          </div>
        </div>
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>

        
    </div>
  );
}
