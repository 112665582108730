import React from "react";
import Text from "../../Components/Text/Text";
import "../../Styles/aboutus.css";
import Footer from "../../Components/Footer/Footer";
import HeaderSection from "../../Components/HeaderSection/HeaderSection";
import Navbar from "../../Components/Navbar/Navbar";
import { Helmet } from "react-helmet";

export default function AboutUs() {
  return (
    <div className="AboutUs">
      <Helmet>
        <title>Who We Are</title>
      </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Who We Are"
            Src="/Images/aboutUs.webp"
            text="About Us <span>/</span>"
            text1="Who We Are"
            RouteLink="/#aboutus"
            RouteLink1="/whoweare"
            Color1="#6C6C6C"
            Color2="black"
          />

          <div className="aboutuscontent">
            <div className="whoweareDetails">
              <div className="dynasysNetworkinnovationhead">
                <Text
                  text="Dynasys Networks"
                  FontSize="3rem"
                  FontWeight="600"
                />
                <Text
                  text="Ideate | Innovate | Implement"
                  FontSize="2rem"
                  Margin="15px 0px 55px 0px"
                  Color="black"
                />
              </div>
              <Text
                FontSize="2.5rem"
                text="DynaSys Networks is a technology company founded in March 2021. The company has two distinct business units: Product Development and Systems Integration.
        In Product Development, our focus areas include Internet of Things and Space. Within Systems Integration, we specialize in cybersecurity, satellite communications, networking solutions, surveillance and security solutions, and power solutions."
              />
            </div>
            <div className="whoweareDetails2">
              <div className="col1">
                <Text text="Our target customer verticals span Telecoms, Defense, Enterprise, and Government, across international markets." />
              </div>
              <div className="col2">
                <Text text="At DynaSys Networks, we are committed to delivering innovative technology solutions designed to meet the unique needs of our diverse customer base. With our expertise in Product Development and Systems Integration, we strive to empower businesses and organizations in an ever-evolving digital landscape. " />
              </div>
            </div>
            {/* <Text  text="Contact us today to explore how our solutions can drive your success." FontWeight="700"/> */}
          </div>
        </div>
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>
    </div>
  );
}
