import React from 'react'
import "./PageDescription.css"
import Text from '../Text/Text'

export default function PageDescription({text, text1}) {
  return (
    <div className='PageDescription'>
        <div className="col1">
            <Text text={text} FontSize="3rem" FontWeight="600" />
        </div>
        <div className="col2">
            <Text text={text1}  FontSize="2.5rem"/>
        </div>
    </div>
  )
}
