import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import MyButton from "../../../Components/Button/Button";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import "../Styles/MousSigningCeremony.css";

export default function DynasysXiplink() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>DynaSys Networks and Xiplink</title>
      </Helmet>
      
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Press Releases"
            // RouteLink="/press_releases/hamid_nawaz_press_release"
            RouteLink1="/press_releases"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
          <div className="MousSigningCeremonyPress">
          <div className="PressReleaseBodyTopText">
              <Text
                text="DynaSys Networks and Xiplink Awarded Cellular Backhaul Optimization Project in Central Asia"
                FontWeight="900"
                FontSize={"3.5rem"}
                font
                Margin="50px 0px 0px 0px"
              />
              <Text
                text="Wed 11 Oct, 2023"
                FontWeight="500"
                Color="#808080"
                FontSize={"1.8rem"}
                font
                Margin="20px 0px 50px 0px"
              />
            </div>
            <div className="MousCeremonyContent">
            <Text Margin="30px 0px 30px 0px" FontSize={"2.4rem"} text="Optimize 2G Cellular Voice over Satellite and Provide a Path to Simple 4G/5G Migration" />

              <Text  text="<span>October 11, 2023 - Montréal, QC and Dubai, UAE:   </span>  XipLink Inc., the technology leader in Wireless Link Optimization, and DynaSys Networks, an up-and-coming Systems Integrator, are pleased to announce that XipLink’s industry leading Cellular optimization solution has been chosen by a Central Asian Mobile Network Operator. The project delivery is a combination of DynaSys’ services and Xiplink’s technology delivering the most advanced satellite and wireless optimization, assuring the best possible ‘goodput’ and high performance. The network will support XipLink’s Advanced Cellular Compression (ACC) which includes XipLink Realtime optimization (XRT), to support the current 2G voice services. The MNO will implement XipLink to optimize the cellular backhaul bandwidth with header compression, packet coalescing, and QoS, but more importantly enables the MNO to easily migrate to 4G/5G services over satellite links when ready." />
              <Text Margin="30px 0px 30px 0px" text="“It’s a great step forward for DynaSys, now having expanded its customer base and service delivery footprint to Central Asia. We’re ever grateful to our customers for the trust they have in our capabilities. Our partnership with XipLink, both technical and commercial, has been excellent. Having had exposure to their technology for a while now, this project has further strengthened our confidence in XipLink’s products.” stated Ali Akhtar, Founder & CEO, DynaSys Networks." />
              <Text   text="“XipLink is very excited to have partnered with DynaSys to support the requirement of not only optimization of the current 2G services increasing the ARPU, but also enabling the MNO customer to simplify their migration to 4G/5G services, when they upgrade in the near future.”, said Omar Diab, Vice President Global Sales for XipLink. “XipLink’s Advanced Cellular Compression includes GTP transparency, Packet Coalescing, Header Compression, and Acceleration that can be combined with the other supported techniques such as Advanced QoS, Link Balancing and Bonding to provide the highest performance and the fastest ROI to MNOs.”" />

            </div>
            <div className="PressReleaseImage">
              <ImageComp Src="/Images/DynasysXiplinkPressReleasePageImg.jpg" Width="100%" Margin="50px 0px 0px 0px" />
            </div>
            <div className="PressReleaseTextAfterImg">
              <Text Margin="80px 0px 20px 0px"  FontWeight={"800"}  text="About XipLink, Inc." />
              <div className="TextWithLink">
                <Text
                  text="XipLink, a pioneer in wireless link optimization, is leading the way to multi-path and multi-orbit hybrid networking. XipLink’s award winning XipOS software seamlessly integrates satellite, cellular, and terrestrial networks using industry standard SCPS TCP Acceleration, advanced link bonding / balancing, Traffic Steering, QoS and Encryption to deliver a significantly improved user experience over stressed communication links. XipOS is successfully deployed in many vertical industries including maritime, military, cellular backhaul and telco infrastructure. XipLink is a privately-owned company with headquarters in Montreal, Quebec (Canada), integration facilities in Ashburn, Virginia (USA) and field personnel worldwide. For more information about XipLink please visit"
                />
                <MyButton
                  RouteLink="https://www.xiplink.com"
                  FontSize="1.8rem"
                  text="www.xiplink.com."
                  FontWeight="400"
                />
              </div>

              <Text Margin="50px 0px 20px 0px" FontWeight={"800"}  text="About DynaSys Networks" />
              <Text
                  text="DynaSys Networks is a technology startup based in Pakistan and the UAE, focused on driving innovation in Product Development and Systems Integration. DynaSys’ passion for Product Development encompasses IoT and Space, and the Systems Integration expertise covers Satellite Communications, Cybersecurity and Networking Solutions."
                />
               <div className="TextWithLink">
              <Text Margin="80px 0px 0px 0px"  text="For more information about DynaSys Networks, please visit" />
                <MyButton
                  RouteLink="https://dynasysnetworks.com/"
                  FontSize="1.8rem"
                  text="www.dynasysnetworks.com."
                  FontWeight="400"
                />
              </div> 


              <Text Margin="50px 0px 20px 0px" FontWeight={"800"}  text="XipLink Contact" />
              <Text text="Joanna Kotopoulis, Administrative Assistant" />
              <Text  text="4200 Saint Laurent Boulevard, Suite 1010, Montréal QC H2W 2R2" />

              <div className="TextWithLink">
              <Text Margin="0px 3px 0px 0px"  text="(P) 514.848.9640 ext. 225" />
              <div className="TextWithLink2">
              <Text text=" (E)" />
              <MyButton
                  RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=jkotopoulis@xiplink.com"
                  FontSize="1.8rem"
                  text="jkotopoulis@xiplink.com"
                  FontWeight="400"
                />
              </div>
                
              </div> 

              <Text Margin="50px 0px 20px 0px" FontWeight={"800"}  text="DynaSys Networks Contact" />
              <Text text="Muhammad Ali, Manager Administration" />
              <Text text="Block B-B22-012, SRTIP, Sharjah, UAE" />

              <div className="TextWithLink">
              <Text Margin="80px 0px 0px 0px"  text="(E)" />
                <MyButton
                  RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com"
                  FontSize="1.8rem"
                  text="info@dynasysnetworks.com"
                  FontWeight="400"
                  Margin={"0px 0px 50px 0px"}
                />
              </div> 

              </div>
            {/* <div className="Press_Release_bottom">
              <div className="SiteLinkSec">
                <Text
                  text="For any inquiries, please reach out at"
                  Margin="0px 2px 0px 0px"
                  FontSize="1.8rem"
                />
                <MyButton
                  RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com"
                  FontSize="1.8rem"
                  text="info@dynasysnetworks.com"
                  FontWeight="400"
                />
              </div>
            </div> */}
          </div>
            
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
        
    </div>
  );
}
