import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import MyButton from "../../../Components/Button/Button";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import "../Styles/MousSigningCeremony.css";

export default function MousSigningCeremony() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>IoT License</title>
      </Helmet>
      
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Press Releases"
            // RouteLink="/press_releases/hamid_nawaz_press_release"
            RouteLink1="/press_releases"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
          <div className="MousSigningCeremonyPress">
          <div className="PressReleaseBodyTopText">
              <Text
                text="DynaSys Networks receives IoT LPWAN License from Pakistan Telecommunication Authority"
                FontWeight="900"
                FontSize={"3.5rem"}
                font
                Margin="50px 0px 0px 0px"
              />
              <Text
                text="Wed 06 Sep, 2023"
                FontWeight="500"
                Color="#808080"
                FontSize={"1.8rem"}
                font
                Margin="20px 0px 50px 0px"
              />
            </div>
            <div className="MousCeremonyContent">
              <Text  text="<span>6th September 2023 – Islamabad, Pakistan</span> – DynaSys Networks announced today that the Pakistan Telecommunication Authority has issued an IoT LPWAN license to it for a 5-year period, which is renewable upon the completion of the license term." />
              <Text Margin="30px 0px 30px 0px" text="With this license, DynaSys has met the regulatory requirements for starting a full-fledged IoT service in Pakistan, centred around ALEF, the innovative platform that DynaSys is launching in the country. ALEF will be the first unified IoT platform in the country." />
              <Text text="The development and launch of an integrated IoT platform with a full array of hosting solutions, transmission network, end-user devices, and use cases will have a positive impact on various sectors, including telecommunications, manufacturing, agriculture, healthcare, construction, and government. This partnership strives to pave the way for enhanced productivity, workload efficiency, cost-effectiveness, and the emergence of new business models ushering in the use of technology at an unprecedented scale in the country, thus contributing to Pakistan's economic growth." />
              <Text  Margin="30px 0px 30px 0px"text="“It was quite encouraging to see how smooth PTA has made the process of acquiring the IoT LPWAN license. We appreciate the genuine positivity from PTA in boosting organizations like ours to develop the IoT ecosystem in Pakistan. With the license in place, we’re now fully focussed on building partnerships for hosting our IoT platform, enabling network rollout, and signing up customers,” stated Ali Akhtar, Founder & CEO, DynaSys Networks." />
            </div>
            <div className="MousCeremonyPressImage">
              <ImageComp Src="/Images/mousPressReleasePAge.webp" Width="auto" Margin="50px 0px 0px 0px" />
              <div className="MousCeremonyPressImageCaption" >
              <Text  Margin="50px 0px 30px 0px" FontSize="1.2rem" text="Ali Akhtar, CEO of DynaSys Networks, accompanied by Imran Farooq, CTO DynaSys (left) and Ikram ul Haq, Director RBS, PTA, signing the LPWAN IoT License at a ceremony held at PTA HQ in Islamabad Pakistan." />
              </div>
            </div>

            
           


            <div className="Press_Release_bottom">
              <div className="SiteLinkSec">
                <Text
                  text="For any inquiries, please reach out at"
                  Margin="0px 2px 0px 0px"
                  FontSize="1.8rem"
                />
                <MyButton
                  RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com"
                  FontSize="1.8rem"
                  text="info@dynasysnetworks.com"
                  FontWeight="400"
                />
              </div>
            </div>
          </div>
            
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
        
    </div>
  );
}
