import React from "react";
import Text from "../Text/Text";
import ImageComp from "../ImageComp/ImageComp";
import "./ThreeColumnWrapper.css";

export default function ThreeColumnWrapper({
  ImgSrc2,
  ImgSrc1,
  Col1Text,
  Col2Text,
}) {
  return (
    <div className="ThreeColumnWrapper">
      {ImgSrc2 ? (
        <div className="col4">
          <ImageComp Src={ImgSrc2} Width="100%" />
        </div>
      ) : (
        ""
      )}

      <div className="col1">
        <Text text={Col1Text} Margin="0px 0px 25px 0px" />
      </div>
      <div className="col2">
        <Text text={Col2Text} Margin="0px 0px 25px 0px" />
      </div>
      {ImgSrc1 ? (
        <div className="col3">
          <ImageComp Src={ImgSrc1} Width="100%" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
