import React from 'react'
import "./PageDescription2.css"
import Text from '../Text/Text'
import ImageComp from '../ImageComp/ImageComp'

export default function PageDescription2({Src, Src1, text, text1}) {
  return (
    <div className='PageDescription2'>
        <div className="pageDivider">
        <ImageComp Src={Src} />
      </div>

        <div className="PageDescription3">
            <div className="col1"></div>
            <div className="col2">
          <Text text={text} FontSize="2.2rem" FontWeight="600" Margin="0px 0px 25px 0px" />
          <Text text={text1} Color="black" />
        </div>
            <div className="col3">
            <ImageComp Src={Src1} />
            </div>
        </div>
    </div>
  )
}
