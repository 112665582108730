import React, { useState } from "react";
import "./MobMenu.css";
import MyButton from "../Button/Button";
import { HashLink } from "react-router-hash-link";
import Text from "../Text/Text";

const DropdownMenu = () => {
  const [activeDropdown, setActiveDropdown] = useState(-1);

  const handleItemClick = (index) => {
    setActiveDropdown((prevActive) => (prevActive === index ? -1 : index));
  };

  const closeAllDropdowns = () => {
    setActiveDropdown(-1);
  };

  return (
    <nav>
      <div className="SiteLogo">
        <MyButton RouteLink="/" Src="/Images/SiteLogo.svg" ImgWidth="90%" />
      </div>
      <ul className="itemList">
        <li>
          <div className="mobmenubtn">
            <MyButton
              handleClick={() => handleItemClick(0)}
              text="About Us"
              Margin="0px 50px 0px 0px"
              FontSize="2.5rem"
              FontWeight="600"
              Color="black"
            />
            <img src="/Images/mobiledrop.svg" alt="" />
          </div>
          <ul
            className={`dropdown ${
              activeDropdown === 0 ? "open" : ""
            } aboutusdrop`}
          >
            <li>
              <div className="aboutItemdropcontent">
                <img src="/Images/mobdropright.svg" alt="" />{" "}
                <MyButton
                  RouteLink="/whoweare"
                  text="Who We Are"
                  FontSize="2rem"
                  FontWeight="600"
                  Color="#6C6C6C"
                />
              </div>
            </li>
            <li>
              <div className="aboutItemdropcontent">
                <img src="/Images/mobdropright.svg" alt="" />{" "}
                <MyButton
                  RouteLink="/leadership"
                  text="Our Leadership"
                  FontSize="2rem"
                  FontWeight="600"
                  Color="#6C6C6C"
                />
              </div>
            </li>
          </ul>
        </li>
        <li>
          <div className="mobmenubtn">
            <MyButton
              handleClick={() => handleItemClick(1)}
              text="Solutions"
              Margin="0px 50px 0px 0px"
              FontSize="2.5rem"
              FontWeight="600"
              Color="black"
            />
            <img src="/Images/mobiledrop.svg" alt="" />
          </div>
          <ul
            className={`dropdown ${
              activeDropdown === 1 ? "open" : ""
            } solutionsdrop`}
          >
            <li>
              <div className="aboutItemdropcontent">
                <img src="/Images/mobdropright.svg" alt="" />{" "}
                <MyButton
                  RouteLink="/Iot"
                  text="Internet of Things"
                  FontSize="2rem"
                  FontWeight="600"
                  Color="#6C6C6C"
                />
              </div>
            </li>
            <li>
              <div className="aboutItemdropcontent">
                <img src="/Images/mobdropright.svg" alt="" />
                <MyButton
                  RouteLink="/satellites"
                  text="Satellite Communications"
                  FontSize="2rem"
                  FontWeight="600"
                  Color="#6C6C6C"
                />
              </div>
            </li>

            <li>
              <div className="aboutItemdropcontent">
                <img src="/Images/mobdropright.svg" alt="" />
                <MyButton
                  RouteLink="/cybersecurity"
                  text="Cybersecurity"
                  FontSize="2rem"
                  FontWeight="600"
                  Color="#6C6C6C"
                />
              </div>
            </li>
            <li>
              <div className="aboutItemdropcontent">
                <img src="/Images/mobdropright.svg" alt="" />
                <MyButton
                  RouteLink="/networks"
                  text="Networking Solutions"
                  FontSize="2rem"
                  FontWeight="600"
                  Color="#6C6C6C"
                />
              </div>
            </li>
            <li>
              <div className="aboutItemdropcontent">
                <img src="/Images/mobdropright.svg" alt="" />
                <MyButton
                  RouteLink="/surveillance"
                  text="Surveillance"
                  FontSize="2rem"
                  FontWeight="600"
                  Color="#6C6C6C"
                />
              </div>
            </li>
            <li>
              <div className="aboutItemdropcontent">
                <img src="/Images/mobdropright.svg" alt="" />
                <MyButton
                  RouteLink="/powersolutions"
                  text="Power Solutions"
                  FontSize="2rem"
                  FontWeight="600"
                  Color="#6C6C6C"
                />
              </div>
            </li>
            <li>
              <div className="aboutItemdropcontent">
                <img src="/Images/mobdropright.svg" alt="" />
                <MyButton
                  RouteLink="/professionalservices"
                  text="Professional Services"
                  FontSize="2rem"
                  FontWeight="600"
                  Color="#6C6C6C"
                />
              </div>
            </li>
          </ul>
        </li>
        <li>
          <HashLink smooth to="/#Verticals">
            <MyButton
              text="Verticals"
              Margin="0px 50px 0px 0px"
              FontSize="2.5rem"
              FontWeight="600"
              Color="black"
            />
          </HashLink>
        </li>
        <li>
          <MyButton
            RouteLink="/contactus"
            text="Contact Us"
            FontSize="2.5rem"
            FontWeight="600"
            Margin="0px 50px 0px 0px"
            Color="black"
          />
        </li>
        <div className="mobJoin">
          <Text text="Join the Network" Color="#7B7B7B" FontSize="1.6rem" />
          <MyButton
            RouteLink="https://www.linkedin.com/company/dynasysnetworks/"
            Src="/Images/linkedin.svg"
            Margin="0px 0px 0px 10px"
          />
        </div>
      </ul>
    </nav>
  );
};

export default DropdownMenu;
