import React from "react";
import HeaderSection from "../../Components/HeaderSection/HeaderSection";
import PageDescription from "../../Components/PageDescription/PageDescription";
import PageDescription2 from "../../Components/PageDescription2/PageDescription2";
import { Helmet } from "react-helmet";
import SolutionSlider from "../../Components/SolutionSlider/SolutionSlider";

export default function Cybersecurity() {
  return (
    <div className="Cybersecurity">
      <Helmet>
        <title>Cybersecurity</title>
      </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Cybersecurity  "
            Src="/Images/cybersecurityBanner.webp"
            text="Solutions <span>/</span>"
            text1=" Cybersecurity"
            RouteLink="/#SolutionSlider"
            RouteLink1="/cybersecurity"
            Color1="#6C6C6C"
            Color2="black"
          />

          <PageDescription
            text="Cybersecurity"
            text1="Prevent and address cyber threats with our dynamic cybersecurity solutions. Our scalable services leverage  best-of-breed components, keeping you ahead in an ever-changing threat landscape. Trust our comprehensive and adaptable approach to protect your business efficiently and consistently."
          />

          <PageDescription2
            Src="/Images/cybersecurityDivider.webp"
            text="Safeguard Your Business in the Evolving Cybersecurity Landscape"
            text1="Our solutions are designed to provide comprehensive protection for your operations, utilizing advanced technologies, industry best practices, and a team of dedicated experts committed to securing your business.<br><br>With us as your cybersecurity partner, you can rest assured that your organization is equipped with robust defenses against evolving threats. <br><br>Gain peace of mind and focus on your core business, knowing that your security is in capable hands."
            Src1="/Images/cybersecurity1.webp"
          />

          {/* Solution Slider */}
          <div className="SolutionSlider">
            <SolutionSlider />
          </div>
        </div>
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>
    </div>
  );
}
