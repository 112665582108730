import React, { useState } from "react";
import "../../Styles/ContactUs.css";
import { Link, NavLink } from "react-router-dom";
import Text from "../../Components/Text/Text";
import ImageComp from "../../Components/ImageComp/ImageComp";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { Helmet } from "react-helmet";
import ClipLoader from "react-spinners/ClipLoader";


export default function ContactUs() {
  function ContactForm() {
          // spinner loader
let [loading, setLoading] = useState(false);
let [color, setColor] = useState("white");
    const [formData, setFormData] = useState({});

    function validEmail(email) {
      var re =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      return re.test(email);
    }

    function validateHuman(honeypot) {
      if (honeypot) {
        console.log("Robot Detected!");
        return true;
      } else {
        console.log("Welcome Human!");
      }
    }

    function getFormData(form) {
      var elements = form.elements;

      var fields = Object.keys(elements)
        .filter(function (k) {
          return elements[k].name !== "honeypot";
        })
        .map(function (k) {
          if (elements[k].name !== undefined) {
            return elements[k].name;
          } else if (elements[k].length > 0) {
            return elements[k].item(0).name;
          }
        })
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos && item;
        });

      var formData = {};
      fields.forEach(function (name) {
        var element = elements[name];
        formData[name] = element.value;
        if (element.length) {
          var data = [];
          for (var i = 0; i < element.length; i++) {
            var item = element.item(i);
            if (item.checked || item.selected) {
              data.push(item.value);
            }
          }
          formData[name] = data.join(", ");
        }
      });

      // add form-specific values into the data
      formData.formDataNameOrder = JSON.stringify(fields);
      formData.formGoogleSheetName = form.dataset.sheet || "Sheet1"; // default sheet name
      formData.formGoogleSendEmail = form.dataset.email || ""; // no email by default

      console.log(formData);
      return formData;
    }

    function handleFormSubmit(event) {
      event.preventDefault();
      const form = event.target;
      const data = getFormData(form);

      if (data.email && !validEmail(data.email)) {
        var invalidEmail = form.querySelector(".email-invalid");
        if (invalidEmail) {
          invalidEmail.style.display = "block";
          return false;
        }
      } else {
        setLoading(true)
        disableAllButtons(form);
        var url = form.action;
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader(
          "Content-Type",
          "application/x-www-form-urlencoded"
        );
        xhr.onreadystatechange = function () {
          console.log(xhr.status, xhr.statusText);
          console.log(xhr.responseText);
          var formElements = form.querySelector(".form-elements");
          if (formElements) {
            formElements.style.display = "none"; // hide form
          }
          var thankYouMessage = form.querySelector(".thankyou_message");
          if (thankYouMessage) {
            setLoading(false)
            thankYouMessage.style.display = "flex";
          }
          return;
        };
        var encoded = Object.keys(data)
          .map(function (k) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
          })
          .join("&");
        xhr.send(encoded);
      }
    }

    function disableAllButtons(form) {
      var buttons = form.querySelectorAll("button");
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].disabled = true;
      }
    }

    return (
      <form
        className="gform pure-form pure-form-stacked"
        onSubmit={handleFormSubmit}
        data-email="info@dynasysnetworks.com"
        // data-email="muslimhussain89@gmail.com"
        action="https://script.google.com/macros/s/AKfycbyKpiYx1BmKFXQZ-NLZ7mLOmUC7aGJtPEde3sFtmJObMRMSDJCk4egGRVFap6i1XxIQ/exec"
      >
        <input type="text" name="Name" placeholder="Name" required />
        <input type="email" name="Email" placeholder="Email" required />
        <input type="text" name="Company Name" placeholder="Company Name" />
        <textarea
          name="Message"
          cols="10"
          rows="1"
          placeholder="What would you like to discuss"
          required
        ></textarea>

        <div className="Send_Spinner">
      <button className="submit press-effect ">send</button>
      <div className="sweet-loading">

        <ClipLoader
          color={color}
          loading={loading}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      </div>


        <div className="thankyou_message">
          <h1>Thanks for contacting us!</h1>
          <div className="close-btn">
            <Link to="/">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </Link>
          </div>
        </div>
      </form>
    );
  }

  return (
    <>
      <div className="ContactUs">
        <Helmet>
          <title>Contact Us</title>
        </Helmet>
        <div className="contactus">
          <div className="contactForm">
            {/* <h2>Contact Us</h2> */}
            <ContactForm />
            <div className="bottomText">
              <div className="officelocationHead">
                <Text
                  text="Pakistan"
                  Color="white"
                  FontSize="3rem"
                  FontWeight={"700"}
                  Margin="25px 0px 15px 0px"
                />
              </div>
              <div className="officelocationAddress d-flex">
                <div className="OfficeAddressSec">
                  <Text
                    text="Rawalpindi"
                    Color="white"
                    FontSize="2rem"
                    Margin="25px 0px 15px 0px"
                  />
                  <Text
                    FontSize="1.6rem"
                    Color="#B5B5B5"
                    Margin="25px 0px 15px 0px"
                    text="Office No. 210, Block 2, Alpha Techno Square, NASTP, Old Airport, Chaklala, Rawalpindi, Pakistan"
                  />
                </div>
                <div className="OfficeAddressSec">
                  <Text
                    text="Karachi"
                    Color="white"
                    FontSize="2rem"
                    Margin="25px 0px 15px 0px"
                  />
                  <Text
                    FontSize="1.6rem"
                    Color="#B5B5B5"
                    Margin="25px 0px 15px 0px"
                    text="Office No. 227, 2nd Floor, NASTP Silicon 1, Shahra-e-Faisal, Karachi, Pakistan"
                  />
                </div>
              </div>

              {/* UAE */}

              <div className="UaeofficelocationHead">
                <Text
                  text="UAE"
                  Color="white"
                  FontSize="3rem"
                  FontWeight={"700"}
                  Margin="25px 0px 15px 0px"
                />
              </div>
              <div className="UaeofficelocationAddress">
                <div className="OfficeAddressSec">
                  <Text
                    text="Sharjah"
                    Color="white"
                    FontSize="2rem"
                    Margin="25px 0px 15px 0px"
                  />
                  <Text
                    FontSize="1.6rem"
                    Color="#B5B5B5"
                    Margin="25px 0px 15px 0px"
                    text="Block B-B22-012, SRTIP, <br>Sharjah, UAE"
                  />
                </div>
              </div>

              <div className="ContactDetails">
                <div className="PhoneDetails">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                  </svg>
                  <Text
                    Margin={"0px 0px 0px 10px"}
                    FontSize="1.6rem"
                    Color="white"
                    text="+92 51 6124855"
                  />
                </div>

                <div className="EmailDetails">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                  </svg>
                  <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com">
                    {" "}
                    info@dynasysnetworks.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="sideImage">
            <img src="/contactimage.webp" alt="" />
            {/* <a href="/">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
            </a> */}
          </div>
        </div>
          
      </div>
    </>
  );
}
